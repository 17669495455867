import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, logInWithEmailAndPassword, signInWithGoogle, signInWithGoogleRedirect, debugRedirectResult, signInWithSAML } from "./firebase";
import { getRedirectResult, getAdditionalUserInfo } from "firebase/auth"
import { useAuthState } from "react-firebase-hooks/auth";
import "./Login.css";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (loading) {
      // maybe trigger a loading screen
      return;
    }
    if (user) navigate("/dashboard")
    else {
        debugRedirectResult().then(magic => {
            console.log("I guess this resolved " + JSON.stringify(magic));
            // navigate("/dashboard");
        }
        );
    }
  }, [user, loading]);

  // useEffect(() => {
  //   auth
  //       .getRedirectResult()
  //       .then(function(result) {
  //         console.log(result);
  //         if (result.credential) {
  //           // This gives you a Google Access Token. You can use it to access the Google API.
  //           // var token = result.credential.accessToken;
  //           // setToken(token);
  //           // ...
  //         }
  //         // The signed-in user info.
  //         var user = result.user;
  //         console.log(user);
  //         // setData(user);
  //         if (user) navigate("/dashboard");
  //       })
  //       .catch(function(error) {
  //         // Handle Errors here.
  //         var errorCode = error.code;
  //         var errorMessage = error.message;
  //         // The email of the user's account used.
  //         console.log(errorCode, errorMessage);
  //         // ...
  //       });
  // }, []);

    useEffect(() => {
        const debugRedirectResult = async () => {
            try {
                const result = await getRedirectResult(auth)
                if (result) {
                    const details = getAdditionalUserInfo(result)
                    console.log(details) // details.isNewUser to determine if a new or returning user
                } else {
                    // Everything is fine
                }
            } catch (error) {
                console.log(error) // Debug errors from redirect response
            }
        }
        // signInWithRedirect()
    }, [])

  return (
    <div className="login">
      <div className="login__container">
        <input
          type="text"
          className="login__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <input
          type="password"
          className="login__textBox"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <button
          className="login__btn"
          onClick={() => logInWithEmailAndPassword(email, password)}
        >
          Login
        </button>
        <button className="login__btn login__google" onClick={signInWithGoogleRedirect}>
          Login with Google
        </button>
        <button className="login__btn login__saml" onClick={signInWithSAML}>
          Login with SAML
        </button>
        <div>
          <Link to="/reset">Forgot Password</Link>
        </div>
        <div>
          Don't have an account? <Link to="/register">Register</Link> now.
        </div>
      </div>
    </div>
  );
}

export default Login;
