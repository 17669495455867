import { initializeApp } from "firebase/app";
import {
  GoogleAuthProvider,
  SAMLAuthProvider,
  getAuth,
  signInWithPopup,
  signInWithRedirect,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut, getRedirectResult, getAdditionalUserInfo,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  doc,
  setDoc,
} from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCBQxeJpDTbAL1CfrSlM8Ilxwgcb48IqgM",
  authDomain: "gruff1-382819.firebaseapp.com",
  projectId: "gruff1-382819",
  storageBucket: "gruff1-382819.appspot.com",
  messagingSenderId: "189291661168",
  appId: "1:189291661168:web:889d2e641c6e31299eb1b5"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const debugRedirectResult = async () => {
  try {
    const result = await getRedirectResult(auth)
    if (result) {
      const details = getAdditionalUserInfo(result)
      console.log(details) // details.isNewUser to determine if a new or returning user
    } else {
      console.log("No result from getRedirectResult")
      // Everything is fine
    }
  } catch (error) {
    console.log(error) // Debug errors from redirect response
  }
}

const googleProvider = new GoogleAuthProvider();
const samlProvider = new SAMLAuthProvider("saml.jumpcloud-demo");
const signInWithSAML = async () => {
  try {
    const res = await signInWithPopup(auth, samlProvider);
    const user = res.user;
    console.log("Got this stupid SAML user: " + JSON.stringify(user))
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    console.log("Query:" +  JSON.stringify(q))
    const docs = await getDocs(q);
    console.log("Got this stupid saml document: " + JSON.stringify(docs))
    if (docs.docs.length === 0) {
      console.log("Adding this stupid saml document: " + JSON.stringify({
        uid: user.uid,
        name: user.displayName,
        authProvider: "saml2",
        email: user.email,
      }))
      const docRef = doc(db, 'users', user.uid)
      await setDoc(docRef, {
        uid: user.uid,
        name: user.displayName,
        authProvider: "saml2",
        email: user.email,
      }, {
        merge: true
      });
      // await addDoc(collection(db, "users"), {
      //   uid: user.uid,
      //   name: user.displayName,
      //   authProvider: "saml2",
      //   email: user.email,
      // });
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};


const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    console.log("Got this stupid2 google user: " + JSON.stringify(user))
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    //console.log("Query:" +  JSON.stringify(q))

    const docs = await getDocs(q);
    //console.log("Got this stupid google document: " + JSON.stringify(docs))
    console.log("Printed stupid google doc")
/*
    await setDoc(doc(db, "users", user.uid), {
      uid: user.uid,
      name: user.displayName,
      authProvider: "google",
      email: user.email,
    })

 */


    if (docs.docs.length === 0) {
      /*
      console.log("Trying to add the fucking google document: " + JSON.stringify({
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      }));
       */

      const docRef = doc(db, 'users', user.uid)
      await setDoc(docRef, {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      }, {
        merge: true
      });
      // await setDoc(doc(db, "users", user.uid), {
      //   uid: user.uid,
      //   name: user.displayName,
      //   authProvider: "google",
      //   email: user.email,
      // });
    }


  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const signInWithGoogleRedirect = async () => {
  try {
    await signInWithRedirect(auth, googleProvider);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const registerWithEmailAndPassword = async (name, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    const user = res.user;
    const docRef = doc(db, 'users', user.uid)
    await setDoc(docRef, {
      uid: user.uid,
      name: user.displayName,
      authProvider: "local",
      email,
    }, {
      merge: true
    });
    // await addDoc(collection(db, "users"), {
    //   uid: user.uid,
    //   name,
    //   authProvider: "local",
    //   email,
    // });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    alert("Password reset link sent!");
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const logout = () => {
  signOut(auth);
};

export {
  auth,
  db,
  debugRedirectResult,
  signInWithGoogle,
  signInWithGoogleRedirect,
  signInWithSAML,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
};
